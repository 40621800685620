/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import ReactPlayer from "react-player";
import { useLocation, withRouter } from "react-router-dom";

import Duration from "./Duration";
import Icons from "../../components/Icons";

const testUrl = "https://www.youtube.com/watch?v=9Twe02EdG4w&rel=0";
// const testUrl = 'https://vimeo.com/431444293';
// const testUrl = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';

class VideoPlayerComponent extends Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
    this.inputElement = React.createRef();
  }
  state = {
    url: null,
    pip: false,
    playing: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    showControls: false,
  };

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      playing: true,
    });
  };

  passDataToRN(obj = {}) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ ...this.state, ...obj })
    );
    window.parent?.postMessage(
      JSON.stringify({ ...this.state, ...obj })
    );
  }

  componentDidMount() {
    const that = this;
    // document.addEventListener("message", function (e) {
    //   // console.log('RECEIVED MESSAGE FROM NATIVE >> ', e.data)
    //   // alert('DATA IS >> '+ e.data.toString())
    //   that.setState({temp: e.data});
    // },false);

    document.addEventListener("message", function (event) {
      that.setState({ temp: event.data });
    });
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const url = params.get("url");
    const duration = params.get("duration");
    const played = params.get("played");
    console.log("PLAYED >> ", played, this.playerRef);
    // console.log('PARAM URL >> ', params, url, duration);
    if (url) {
      this.load(url);
    }

    if (url && played) {
      setTimeout(() => {
        this.playerRef?.current.seekTo(played);
        console.log("PLAYED CALL >> ");
      }, 1000);
    }
  }

  handlePlayPause = () => {
    // console.log('PLAY PAUSE >> ')
    this.setState({ playing: !this.state.playing });
    this.passDataToRN({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
    this.passDataToRN({ url: null, playing: false });
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };

  handlePlay = () => {
    this.setState({ playing: true });
    this.passDataToRN({ playing: true });
  };

  handleEnablePIP = () => {
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    this.setState({ pip: false });
    this.passDataToRN({ pip: false });
  };

  handlePause = () => {
    this.setState({ playing: false });
    this.passDataToRN({ playing: false });
    // this.handlePlay()
  };

  handleSeekMouseDown = () => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.playerRef.current.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    // console.log('onProgress', state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
    this.passDataToRN({ progressState: state });
  };

  handleEnded = () => {
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    // console.log('onDuration', duration);
    this.setState({ duration });
    this.passDataToRN({ duration });
  };

  backward10Seconds = () => {
    const playTime = this.state.played - 0.03;
    this.playerRef.current.seekTo(playTime);
  };
  forward10Seconds = () => {
    const playTime = this.state.played + 0.03;
    this.playerRef.current.seekTo(playTime);
  };

  render() {
    const { url, playing, volume, played, duration, playbackRate, showControls } = this.state;

    // console.log('RENDER >> LOAD URL >> ', played)
    // console.log('RENDER >> LOAD URL >> ', this.playerRef)
    // console.log('RENDER >> LOAD URL >> ', this.state)

    return (
      <div
        ref={this.inputElement}
        className="relative h-screen w-full"
        onClick={() => console.log("ON CLICK ")}
      >
        <div className=" z-50 left-0 right-0 h-full">
          <div className="h-full bg-gray-100 shadow-xl flex flex-col relative overflow-hidden">
            <div className="-z-0 h-full flex-1 overflow-hidden">
              {this.state.url && (
                <ReactPlayer
                  ref={this.playerRef}
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={url}
                  loop={true}
                  playing={playing}
                  playbackRate={playbackRate}
                  volume={volume}
                  onReady={() => {}}
                  onStart={() => this.setState({showControls: true})}
                  onPlay={this.handlePlay}
                  onEnablePIP={this.handleEnablePIP}
                  onDisablePIP={this.handleDisablePIP}
                  onPause={this.handlePause}
                  onBuffer={() => console.log("onBuffer")}
                  onPlaybackRateChange={this.handleOnPlaybackRateChange}
                  onSeek={(e) => console.log("onSeek", e)}
                  onEnded={this.handleEnded}
                  onError={(e) => console.log("onError", e)}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                  onPlaybackQualityChange={(e) =>
                    console.log("onPlaybackQualityChange", e)
                  }
                />
              )}
            </div>
            <div
              className={`absolute ${
                "bg-black bg-opacity-20"
              } left-0 right-0 top-0 flex flex-col p-10 `}
            ></div>
            <div
              className={`absolute  ${showControls ? '': 'hidden'} bg-opacity-20 bg-black left-0 right-0 bottom-0 flex flex-col px-6 pt-8 pb-2 transition-opacity`}
            >
              {/* <div className='flex flex-row items-center w-full'>
                <div className='flex-1 flex flex-row items-center'>
                  <div className='flex-1 text-white'>
                    <div
                      className='mr-auto font-medium text-base'
                    >
                      Jyot sound cloud title
                    </div>
                    <div
                      className='mr-auto font-medium text-xs text-text2'
                      numberOfLines={2}
                      ellipsizeMode='tail'
                    >
                      artist
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="flex flex-row items-center flex-1 justify-center gap-10 py-4">
                {/* <div
                  className="cursor-pointer"
                // onClick={skipToPrevious}
                >
                  <img src={Icons.PlayerPrevious} width={34} height={34} />
                </div> */}
                <div
                  className="cursor-pointer"
                  onClick={this.backward10Seconds}
                >
                  <img src={Icons.Backward10Seconds} width={34} height={34} />
                </div>

                <div
                  className="rounded-full relative p-6 cursor-pointer"
                  onClick={() => this.handlePlayPause()}
                >
                  <div className="top-0 bottom-0 right-0 left-0 absolute bg-white opacity-10 rounded-full" />
                  {playing ? (
                    <img src={Icons.PlayerPause} width={34} height={34} />
                  ) : (
                    <img src={Icons.PlayerPlay} width={34} height={34} />
                  )}
                </div>

                <div className="cursor-pointer" onClick={this.forward10Seconds}>
                  <img src={Icons.Forward10Seconds} width={34} height={34} />
                </div>
                {/* <div
                  className="cursor-pointer"
                // onClick={skipToNext}
                >
                  <img src={Icons.PlayerNext} width={34} height={34} />
                </div> */}
              </div>
              <div>
                <div className="relative h-4">
                  <input
                    className="w-full appearance-none bg-transparent rounded-full cursor-pointer absolute z-50"
                    type="range"
                    min={0}
                    max={1}
                    step="any"
                    value={played}
                    onMouseDown={this.handleSeekMouseDown}
                    onChange={this.handleSeekChange}
                    onMouseUp={this.handleSeekMouseUp}
                    onTouchStart={this.handleSeekMouseDown}
                    onTouchMove={this.handleSeekChange}
                    onTouchEnd={this.handleSeekMouseUp}
                  />
                  <div class="w-full bg-gray-200 rounded-full h-1.5 absolute m-auto top-0 bottom-0 -z-0">
                    <div
                      class="bg-primary h-1.5 rounded-full duration-700 transition-all"
                      style={{ width: `${played * 100}%` }}
                    ></div>
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <Duration
                    seconds={duration * played}
                    className="font-bold text-sm text-white"
                  />
                  <div className="flex flex-row justify-between text-white">
                    <Duration
                      seconds={duration * (1 - played)}
                      className="font-bold text-sm text-white"
                    />
                    /
                    <Duration
                      seconds={duration}
                      className="font-bold text-sm text-white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function VideoPlayer(props = {}) {
  const location = useLocation();
  return <VideoPlayerComponent {...props} location={location} />;
}

export default VideoPlayer;

// import React from 'react'
// import ReactPlayer from 'react-player';

// function SoundCloudPlayer() {
//     // const url = "https://soundcloud.com/tycho/tycho-awake"
//     // const url = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/293&amp;"
//     // const url = "https://w.soundcloud.com/player/?url=https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji?si=c7340c84768c4741bf998eae842475a4&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing;auto_play=true&amp;"

//     return (
//         <div className=''>
//             {/* <iframe title='sound-cloud' width="100%" height="110" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji?si=c7340c84768c4741bf998eae842475a4&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing;auto_play=true&amp;">
//             </iframe> */}
//             <ReactPlayer url='https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji' />
//         </div>

//     )
// }

// export default SoundCloudPlayer;
