import React, { Component } from "react";

import ReactPlayer from "react-player";
import Duration from "./Duration";
import SoundCloudPlayerWidget from "./SoundCloudPlayerWidget";
import Icons from "../../components/Icons";
import { useLocation } from "react-router-dom";

const testUrl =
  "https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji&auto_play=true";

class SoundCloudPlayerComponent extends Component {
  constructor(props) {
    super(props);
    this.playPauseRef = React.createRef();
    this.inputElement = React.createRef();
  }
  state = {
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  };

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
      playing: true,
    });
  };

  passDataToRN(obj = {}) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ ...this.state, ...obj })
    );
    window.parent?.postMessage(
      JSON.stringify({ ...this.state, ...obj })
    );
  }

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const url = params.get("url");
    const duration = params.get("duration");
    // console.log('PARAM URL >> ', params, url, duration);
    if (url) {
      this.load(url);
    }
  }

  // componentDidMount() {
  //   // this.state.url = url;
  //   setTimeout(() => {
  //     this.load(testUrl)
  //   }, 2000);
  //   let that  = this;
  //   setTimeout(() => {
  //     // this.handlePlay()
  //     this.playPauseRef.current.onclick()
  //     that.load(testUrl)
  //     // this.handlePlayPause()
  //     // console.log('ON CLICK TRIGGERED >> ', that.player?.getInternalPlayer())
  //     // console.log('WINDOW >> ', w);
  //   }, 2000);
  //   setInterval(() => {
  //     // console.log('INTERVAL >> ', this.inputElement)
  //     this.inputElement?.current.click();
  //   }, 200);

  // }

  handlePlayPause = () => {
    // console.log('PLAY PAUSE >> ')
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    // console.log('onPlay');
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    // console.log('onEnablePIP');
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    // console.log('onDisablePIP');
    this.setState({ pip: false });
  };

  handlePause = () => {
    // console.log('onPause');
    this.setState({ playing: true });
    // this.handlePlay()
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    // console.log('onProgress', state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    // console.log('onEnded');
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    // console.log('onDuration', duration);
    this.setState({ duration });
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = this.state;
    const SEPARATOR = " · ";

    // console.log('RENDER >> LOAD URL >> ', url)
    // console.log('RENDER >> REF >> ', this.player)
    // console.log('RENDER >> play pause REF >> ', this.playPauseRef)

    return (
      <div
        ref={this.inputElement}
        className="relative h-full w-full"
        onClick={() => console.log("ON CLICK ")}
      >
        <div className=" z-50 left-0 right-0 ">
          <div>
            <table>
              <tbody>
                <tr>
                  <th>SoundCloud</th>
                  <td>
                    {this.renderLoadButton(
                      "https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji",
                      "Test A"
                    )}
                    {this.renderLoadButton(
                      "https://soundcloud.com/tycho/tycho-awake",
                      "Test B"
                    )}
                    {this.renderLoadButton(
                      "https://soundcloud.com/yunghog/sets/doperaptraxxx",
                      "Playlist"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="h-full flex bg-gray-100 p-4 rounded-2xl shadow-xl m-2 flex-1">
            <div className="w-full">
              <div className="flex flex-row items-center w-full">
                <img
                  className="w-10 h-10 rounded-lg m-2"
                  src="https://images.genius.com/7ea34ad2fa694fb706de3e81dc1588c4.1000x1000x1.jpg"
                />
                <div className="flex-1 flex flex-row items-center">
                  <div className="flex-1">
                    <div className="mr-auto font-medium text-base text-text">
                      Jyot sound cloud title
                    </div>
                    <div
                      className="mr-auto font-medium text-xs text-text2"
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      artist
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                    // onClick={skipToPrevious}
                    >
                      <img src={Icons.PlayerPrevious} width={24} height={24} />
                    </div>

                    <div
                      ref={this.playPauseRef}
                      className="rounded-full relative p-3"
                      onClick={() => this.handlePlayPause()}
                    >
                      <div className="top-0 bottom-0 right-0 left-0 absolute bg-black opacity-10 rounded-full" />
                      {playing ? (
                        <img src={Icons.PlayerPause} width={24} height={24} />
                      ) : (
                        <img src={Icons.PlayerPlay} width={24} height={24} />
                      )}
                    </div>

                    <div
                    // onClick={skipToNext}
                    >
                      <img src={Icons.PlayerNext} width={24} height={24} />
                    </div>
                  </div>
                </div>
              </div>
              {/* <progress max={1} className='w-full h-1 rounded-full bg-primary-500' value={played} /> */}
              <div className="relative h-4">
                <input
                  className="w-full appearance-none bg-transparent rounded-full cursor-pointer absolute z-50"
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={played}
                  onMouseDown={this.handleSeekMouseDown}
                  onChange={this.handleSeekChange}
                  onMouseUp={this.handleSeekMouseUp}
                />
                <div class="w-full bg-gray-200 rounded-full h-1.5 absolute m-auto top-0 bottom-0 -z-0">
                  <div
                    class="bg-primary h-1.5 rounded-full duration-700 transition-all"
                    style={{ width: `${played * 100}%` }}
                  ></div>
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <Duration
                  seconds={duration * (1 - played)}
                  className="font-bold text-sm text-text"
                />
                <div className="flex flex-row justify-between">
                  <Duration
                    seconds={duration * played}
                    className="font-bold text-sm text-text"
                  />
                  /
                  <Duration
                    seconds={duration}
                    className="font-bold text-sm text-text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="absolute z-20 left-0 right-0 bg-white  w-screen h-screen"></div> */}

        <div className="-z-0  left-0 top-0">
          {this.state.url && (
            <ReactPlayer
              ref={this.ref}
              className="react-player"
              width="100%"
              height="100%"
              url={url}
              pip={pip}
              playing={playing}
              controls={controls}
              light={light}
              loop={loop}
              playbackRate={playbackRate}
              volume={volume}
              muted={muted}
              onReady={() => {
                console.log("onReady");
              }}
              onStart={() => console.log("onStart")}
              onPlay={this.handlePlay}
              onEnablePIP={this.handleEnablePIP}
              onDisablePIP={this.handleDisablePIP}
              onPause={this.handlePause}
              onBuffer={() => console.log("onBuffer")}
              onPlaybackRateChange={this.handleOnPlaybackRateChange}
              onSeek={(e) => console.log("onSeek", e)}
              onEnded={this.handleEnded}
              onError={(e) => console.log("onError", e)}
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
              onPlaybackQualityChange={(e) =>
                console.log("onPlaybackQualityChange", e)
              }
            />
          )}
        </div>
      </div>
    );
  }
}

function SoundCloudPlayer(props = {}) {
  const location = useLocation();
  return <SoundCloudPlayerComponent {...props} location={location} />;
}

export default SoundCloudPlayer;

// import React from 'react'
// import ReactPlayer from 'react-player';

// function SoundCloudPlayer() {
//     // const url = "https://soundcloud.com/tycho/tycho-awake"
//     // const url = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/293&amp;"
//     // const url = "https://w.soundcloud.com/player/?url=https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji?si=c7340c84768c4741bf998eae842475a4&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing;auto_play=true&amp;"

//     return (
//         <div className=''>
//             {/* <iframe title='sound-cloud' width="100%" height="110" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji?si=c7340c84768c4741bf998eae842475a4&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing;auto_play=true&amp;">
//             </iframe> */}
//             <ReactPlayer url='https://soundcloud.com/jyotindia/arihant-pad-stuti-navpadji' />
//         </div>

//     )
// }

// export default SoundCloudPlayer;
