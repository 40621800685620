const TypeEnum = Object.freeze({
  LIKE: "LIKE",
  BOOKMARK: "BOOKMARK",
  SHARE: "SHARE",
  ADDTOCALENDAR: "ADDTOCALENDAR",
  DOWNLOAD: "DOWNLOAD",
  REFRESH: "REFRESH",
  LOGIN: "LOGIN",
});

const Status = Object.freeze({
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
});

const AppRequest = (event, data) => {
  return {
    event: event, // event: TypeEnum.NONE,
    data: data, // data: {},
  };
};

const AppResponse = (event, data) => {
  return {
    event: event, // event: TypeEnum.NONE,
    data: data, // data: { status: Status.NONE, data: {} }
  };
};

const onRectMessageSend = (data) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  window.parent.postMessage(JSON.stringify(data), "*");
};

const onRectMessageReceived = (message) => {
  try {
    return JSON.parse(message.data);
  } catch (error) {
    return null;
  }
};

export default {
  TypeEnum,
  Status,
  AppRequest,
  AppResponse,
  onRectMessageSend,
  onRectMessageReceived,
};
